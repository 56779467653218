#warehouse_id_selct,#warehouseCountry_select,
#warehouseState_select,#warehouse_city,#warehouseOwner_Name,#warehouseOwner_Id,
#ownerAddressLine1, #ownerAddressLine2,
#ownerEmailID,#ownerContactNumber{
    /* border: 1px solid indigo;
    border-radius: 10px;
    padding-right: 10px; */
    margin-left: -5px;
    background-color: beige;
    /* padding-left: 6px; */
}

#cancelOwnerBtn,
#addOwnerBtn,
#updateOwnerBtn{
    border-radius: 4px !important;
    min-width: 80px;
}
.warehouseownarMadal{
 min-height: 93% !important;
 top: 1% !important;
}