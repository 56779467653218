#country_name_selct,#ownerid_select{
    /* border: 1px solid indigo;
    border-radius: 10px;
    padding-right: 10px; */
    margin-left: -5px;
    background-color: beige;
    /* padding-left: 6px; */
}

#cancelBillingBtn,
#updateBillingBtn,
#addBillingBtn{
    border-radius: 4px !important;
    min-width: 80px;
}