#state_name,#state_code,#selectCountry{
  
    /* border: 1px solid indigo;
    border-radius: 10px; */
    /* padding-right: 10px;
    padding-left: 6px; */
    margin-left: -5px;
    background-color: beige;
}


#addStateBtn,
#cancelStatebtn,#updateStateBtn{
    border-radius: 4px !important;
    min-width: 80px;
}

.select-wrapper input.select-dropdown.dropdown-trigger,
input,  textarea.materialize-textarea{
    /* border: 1px solid indigo;
    border-radius: 10px; */
    padding-right: 10px;
    margin-left: -5px;
    padding-left: 6px;
    /* background-color: beige; */
}
.modal-fixed-footer_addstate{
    min-height: 70% !important;
    top: 1% !important;
}