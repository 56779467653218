/* appearance for checked radiobutton */
input[type="radio"]:checked {
    background-color: red;
  } 

.productlistDiv{
  min-height: 20vh;
  max-height: 300px;
  overflow-y: auto;
}

.cardheader{
  /* height: 100px; */
  /* height: 60px; */
  /* margin-bottom:10px !important; */
}

.orderItemCard{
  /* background-color: #C8EEFB !important; */
  border-radius: 30px !important;
}

.orderpopmodel { width: 65% !important ; 
  /* height: 75% !important ; */
 } 

 .mergeordermodel{
  height: 40% !important ;
 }



  #customerbasicdetailsCard{
    background-color: #C8EEFB;
    border-radius: 40px;
  }


  #customer_id,#order_date,#received_date,#origin_id,#dest_id,
  #tracking_number,#shiper_order_number,#weight,
  #height,#fedex_charge,#width,#length,#actual_charge
  ,#product_name,#product_price,#product_quantity, #category_name_select,#quantity,
  #phone_id,#handling_fee,#homedelivery_fee,#insurance_fee,#processing_fee
  {
  
    border: 1px solid indigo;
    border-radius: 10px;
    padding-right: 10px;    
    margin-left: -5px;


}
#cancelOrderBtn,#saveOrderBtn,#cancelItemAddBtn,#updateOrder,#printLabel,
#addItemBtn,#closeOrderBtn,#cancelMergeAddBtn,#MergeBtn,#deleteItemBtn{
    border-radius: 40px;
}

.inputbox-height{
  height: 80px !important;
  
}