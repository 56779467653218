#country_name_selct,
#range_initial,#rangel_final,
#unit_charge,#mark_up{
    /* border: 1px solid indigo;
    border-radius: 10px; */
    /* padding-right: 10px; */
    margin-left: -5px;
    /* padding-left: 6px; */
    background-color: beige;
}

#addSlabBtn,
#updateSlabBtn,
#cancelSlabbtn{
    border-radius: 4px !important;
    min-width: 80px;
}
.modal-fixed-footer_addslab{
    min-height:90% !important;
    top: 1% !important;
}