#country_name_select,#warehouse_id_select,
#IP_address,#port_number{
    border: 1px solid rgb(209, 163, 241);
    border-radius: 10px;
    padding-right: 10px;
    margin-left: -5px;
    padding-left: 6px;
}

#cancelPrinterBtn,
#updatePrinterBtn,
#addPrinterBtn{
    border-radius: 40px !important;
}