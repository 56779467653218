.operation-dataDiv{
    /* min-height: 80%; */
    /* height: 40vh; */
    /* min-width: 50rem;
    width:auto;
    overflow-y:auto;
    padding-left: 10px; */
}
.modal { width: 45% !important; 
height: 1000px !important;
/* border-radius: 10px; */
overflow-y: hidden;}
.rowdivPading{
    /* padding: -10px; */
    margin-bottom: -35px !important;
}
.buttonstyle{
    /* border-radius: 4px; */
    /* border: 1px solid #0156ED; */
    background-color: #C8EEFB  ;
}

#ezzid,#name,
#email,#mobile,
#shippingaddress1,#shippingaddress2,#shipping_selectCountry,#shipping_selectstate,#shippingcity,#shippingzip,
#billaddress1,#billaddress2,#bill_selectCountry,#bill_selectstate,#billcity,#billzip{
  
    /* border: 1px solid indigo; */
    /* border-radius: 10px; */
    /* padding-right: 10px; */
    margin-left: -5px;
    background-color: beige;
}

#cancelCountryBtn,
#updateCountryBtn,
#addCountryBtn{
    border-radius: 4px !important;
    min-width: 80px;
}

/* label focus color */
.input-field input[type=text]:focus + label, .materialize-textarea:focus:not([readonly]) + label {
    color: indigo !important;
   }

/* label underline focus color */
   .input-field input[type=text]:focus, .materialize-textarea:focus:not([readonly]) {
    /* border-bottom: 1px solid indigo !important; */
    box-shadow: 0 1px 0 0 indigo !important;
   }

   .tableheight{
       /* max-height: 50vh; */
       overflow-y: auto;
   }
#deflanguage{
    /* border: 1px solid indigo; */
    /* border-radius: 10px; */
    padding-right: 10px;
    margin-left: -5px;
    padding-left: 6px;
}
.modal-fixed-footer_addcountry{
    min-height: 100% !important;
    top: 1% !important;
}