#warehouse_id,#warehouse_name,
#warehouse_address,#warehouse_city,
#state_name_selet,#country_name_selct,#country_name_selet
#warehouse_zipcode,#warehouse_citi,#warehouse_zipcode{
    /* border: 1px solid indigo;
    border-radius: 10px; */
    /* padding-right: 10px; */
    margin-left: -5px;
    /* padding-left: 6px; */
    background-color: beige;
}

#cancelWareHouseBtn,
#updateWareHouseBtn,
#addWareHouseBtn{
    border-radius: 4px !important;
    min-width: 80px;
}
.browser-default{
    background-color:beige
}
/* .modal-fixed-footer_addcountry{
    min-height: 90% !important;
    top: 1% !important;
} */
.warehousemodal{
    min-height: 93% !important;
    top: 1% !important;
}