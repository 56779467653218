#country_name_slect,
#category_id,#category_name,
#custome_duty,#vat{
    /* border: 1px solid indigo; */
    /* border-radius: 10px;
    padding-right: 10px; */
    margin-left: -5px;
    background-color: beige;
    /* padding-left: 6px; */
}

#cancelCategoryBtn,
#updateCategoryBtn,
#addCategoryBtn{
    border-radius: 4px !important;
    min-width: 80px;
}
.modal-fixed-footer_category{
    min-height: 84% !important;
    top: 1% !important;
}