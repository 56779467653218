#country_name_selct,
#discount_name,#discountValue,
#start_date,#end_date{
    /* border: 1px solid indigo;
    border-radius: 10px;
    padding-right: 10px; */
    margin-left: -5px;
    background-color: beige;
    /* padding-left: 6px; */
}

#cancelDiscountBtn,
#updateDiscountBtn,
#addDiscountBtn{
    border-radius: 4px !important;
    min-width: 80px;
}

.datepicker-modal{
    width: 100% !important;
}
.discountModal{
    min-height: 83%;
    top: 3%;
}