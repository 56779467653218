#country_name_selct, #state_name_select,
#broker_name, #company_name, #phone_number, #street_line, 
#city,#zipcode, #selectFlag{
    /* border: 1px solid indigo;
    border-radius: 10px;
    padding-right: 10px; */
    margin-left: -5px;
    /* padding-left: 6px; */
    background-color: beige;
}

#cancelBrokerBtn,
#updateBrokerBtn,
#addBrokerBtn{
    border-radius: 4px !important;
    min-width: 80px;
}
.brokermodal{
    min-height: 90%;
    top: 2%;
}
